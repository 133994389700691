<!--
 * @Author: 赛罗
 * @Date: 2023-09-04 08:59:38
 * @LastEditors: 赛罗
 * @LastEditTime: 2023-09-22 17:19:40
 * @FilePath: \haixiaoke-officialwebsite\src\App.vue
 * @Description: 
-->
<template>
  <div id="app">
    <Head></Head>
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <Footer></Footer>
    <!-- <router-view></router-view> -->
    <el-backtop :right="30" :bottom="100" />
  </div>
</template>

<script>
import Head from '@/components/Head.vue';
import Footer from '@/components/Footer.vue';
export default{
  name:'App',
  components:{
    Head,
    Footer
  }
}

</script>

<style lang="scss" scoped>

</style>