<template>
  <div>
    <header class="header">
      <nav class="navbar">
        <div class="nav-logo" @click="$router.push('/')">
          <img src="@/assets/images/logo.png" />
          <span>广州嗨小克智能科技有限公司</span>
        </div>
        <ul class="nav-menu" ref="navMenu">
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">首页</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">产品介绍</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">方案介绍</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">技术能力</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">关于我们</a>
          </li> -->
          <li class="nav-item" :class="{ active: $route.path == item.path }" v-for="(item, index) in nav" :key="index" @click="toPath(item)">
            <span class="nav-link" :class="{ active: $route.path == item.path }">{{ item.name }}</span>
          </li>
        </ul>
        <div class="hamburger" ref="hamburger" @click="showMenu">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: [
        {
          path: "/",
          name: "首页",
        },
        {
          path: "/product",
          name: "产品介绍",
        },
        {
          path: "/idea",
          name: "方案介绍",
        },
        // {
        //   path: "/skill",
        //   name: "技术能力",
        // },
        {
          path: "/about",
          name: "关于我们",
        },
      ]
    }
  },
  methods: {
    showMenu() {
      this.$refs.hamburger.classList.toggle("active");
      this.$refs.navMenu.classList.toggle("active");
    },
    toPath(item) {
      if(item.path == this.$route.path) return 
      this.$router.push(item.path);
      this.$refs.hamburger.classList.remove("active");
      this.$refs.navMenu.classList.remove("active");
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  // border-bottom: 1px solid #e2e8f0;
  height: 70px;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1.5rem;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-item {
  // margin-left: 5rem;
  min-width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #475569;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    color: #ffffff;
    background: #475569;
  }
  &.active {
    color: #ffffff;
    background: #475569;
  }
}

.nav-link {
  font-size: 1.6rem;
  font-weight: 400;
  // color: #475569;

  &.active {
    // color: #482ff7;
    color: #fff;
  }
}

.nav-link:hover {
  // color: #482ff7;
  color: #fff;
}

.nav-logo {
  display: flex;
  font-size: 2.1rem;
  font-weight: 500;
  // color: #482ff7;
  color: #000000;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 5rem;
  }
}

@media only screen and (max-width: 992px) {
  .nav-menu {
    position: absolute;
    left: -100%;
    top: 7rem;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    height: auto;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    z-index: 999;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 2.5rem 0;
    
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  // Inside the Media Query.

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .nav-logo {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    // color: #482ff7;
    color: #000000;
  }
}
</style>