<template>
  <div class="footer">
    <div class="footer-social">
      <div class="social-item">
        <div class="title">联系我们</div>
        <div>
          <p>电话：+86 13822251895</p>
          <p>电子邮箱：139924680@qq.com</p>
          <p>地址：广州市天河区天河路242号B幢1004室</p>
        </div>
      </div>
      <div class="social-item">
        <div class="title">关注我们</div>
        <div class="qrcode">
          <img
            src="@/assets/images/qrcode.jpg"
            alt=""
          />
          <div>（微信公众号）</div>
        </div>
      </div>
    </div>
    <div class="cpy-right">
      <p>
        Copyright &copy; 广州嗨小克智能科技有限公司所有.
        <span>
          <a href="http://beian.miit.gov.cn/">粤ICP备2023004949号-1 </a>
        </span>
        <span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602007899"
            >粤公网安备 44010602007899</a
          >
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      
    }
  }
}
</script>

<style lang="scss" scoped>
/*-- /footer --*/
.footer {
  padding: 10px;
  background: rgba(0, 0, 0, 0.92);
  box-sizing: border-box;
}

.footer p {
  color: #fff;
  letter-spacing: 2px;
}
.footer-social {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .social-item {
    color: #fff;
    margin-bottom: 10px;
    .title {
      font-size: 22px;
      margin-bottom: 20px;
    }
    .qrcode{
      width: 100px;
      text-align: center;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.cpy-right {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: Microsoft YaHei;
  a {
    color: #fff;
    text-decoration: none;
  }
}
@media screen and (max-width:992px) {
  .footer-social {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .social-item{
      .title{
        text-align: center;
      }
    }
  }
}
</style>