/*
 * @Author: 赛罗
 * @Date: 2023-09-04 08:59:38
 * @LastEditors: 赛罗
 * @LastEditTime: 2023-09-22 17:21:20
 * @FilePath: \haixiaoke-officialwebsite\src\route\index.js
 * @Description: 
 */
import Vue from 'vue';
import VueRouter from "vue-router";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// const routes = [
//   {
//     path: '/',
//     name: 'index',
//     //使用import可以路由懒加载，如果不使用，太多组件一起加载会造成白屏
//     component: () => import('@/views/Index.vue'),
//     redirect: 'home',
//     children: [
//       {
//         path: '/home',
//         name: 'home',
//         component: () => import('@/views/home/Home.vue'),
//         meta: {
//           title: '首页'
//         }
//       },
//       {
//         path: '/product',
//         name: 'product',
//         component: () => import('@/views/product/Product.vue'),
//         meta: {
//           title: '产品介绍'
//         }
//       },
//       {
//         path: '/productDetail',
//         name: 'productDetail',
//         component: () => import('@/views/product/ProductDetail.vue'),
//         meta: {
//           title: '产品详情'
//         }
//       },
//       {
//         path: '/idea',
//         name: 'idea',
//         component: () => import('@/views/idea/Idea.vue'),
//         meta: {
//           title: '方案介绍'
//         }
//       },
//       // {
//       //     path:'/skill',
//       //     name:'skill',
//       //     component:()=>import('@/views/skill/Skill.vue'),
//       //     meta:{
//       //         title:'技术能力'
//       //     }
//       // },
//       {
//         path: '/about',
//         name: 'about',
//         component: () => import('@/views/about/About.vue'),
//         meta: {
//           title: '关于我们'
//         }
//       }
//     ]
//   },
//   //{
//   //配置404页面
//   //path: '/:catchAll(.*)',
//   //name: '404',
//   //component: () => import(''),
//   //}
// ]
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/Product.vue'),
    meta: {
      title: '产品介绍'
    }
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import('@/views/product/ProductDetail.vue'),
    meta: {
      title: '产品详情'
    }
  },
  {
    path: '/idea',
    name: 'idea',
    component: () => import('@/views/idea/Idea.vue'),
    meta: {
      title: '方案介绍'
    }
  },
  // {
  //     path:'/skill',
  //     name:'skill',
  //     component:()=>import('@/views/skill/Skill.vue'),
  //     meta:{
  //         title:'技术能力'
  //     }
  // },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/About.vue'),
    meta: {
      title: '关于我们'
    }
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})
//守卫
router.beforeEach((to, from, next) => {
  NProgress.start() //进度条开始
  next()
})
router.afterEach(() => {
  NProgress.done() // 进度条结束
})

export default router;