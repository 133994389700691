import Vue from 'vue'
import App from './App.vue'

import "./assets/css/reset.css"
import "animate.css"

import VueSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import 'element-ui/lib/theme-chalk/index.css'
import directives from './utils/directives'
import './utils/element'
import vueMetaInfo from 'vue-meta-info'
// 引用
Vue.use(vueMetaInfo)
Vue.use(VueSwiper)
import router from './route'
Vue.config.productionTip = false

Vue.use(directives)

new Vue({
  router,
  render: h => h(App),

  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
