export default {
  install: (Vue) => {
    Vue.directive('fadeIn', {
      bind: function (el, binding, vnode) {
        // 判断是否可以使用
        if ('IntersectionObserver' in window) {
          // IntersectionObserver对象，浏览器原生提供的构造函数
          let observer = new IntersectionObserver((entries) => {
            // intersectionRatio: 目标元素的可见比例，即intersectionRect占boundingClientRect的比例，完全可见时为1，完全不可见时小于等于0
            // 是否交叉
            const isIntersected = entries.some(entry => entry.intersectionRatio > 0);
            if (isIntersected) {
              // 执行绑定的事件
              el.className = el.className + " animate__animated animate__fadeInUp"
              // 停止观察
              observer.unobserve(el);
              // 关闭观察器
              observer.disconnect();
            }
          });

          // 开始观察， el: DOM对象
          observer.observe(el);
        } else {
          console.log(6666);

        }
      }
    })
  }
}